import { Box, Grid, Typography } from '@mui/material';

const ResourceStatusDetail = ({ created_at, updated_at, deleted_at }) => {
    const RenderStatus = ({ label, value }) => {
        return (
            <Box>
                <Typography variant="h6" fontWeight={600}>
                    {label}:
                </Typography>
                <Typography variant="body">{value}</Typography>
            </Box>
        );
    };

    const RenderStatusWithGrid = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={deleted_at ? 4 : 6}>
                    <RenderStatus label="Created At" value={created_at} />
                </Grid>
                <Grid item xs={12} md={deleted_at ? 4 : 6}>
                    <RenderStatus label="Updated At" value={updated_at} />
                </Grid>
                {deleted_at && (
                    <Grid item xs={12} md={4}>
                        <RenderStatus label="Deleted At" value={deleted_at} />
                    </Grid>
                )}
            </Grid>
        );
    };

    return <RenderStatusWithGrid />;
};

export default ResourceStatusDetail;
