import { getHelper } from '../helpers/api';
export async function getUsersDashboardStatsForAdmin() {
    return await getHelper('/users/count');
}

export async function getLoginHistory(page, size) {
    return await getHelper(`/users/login-history?page=${page}&size=${size}`);
}

export async function getLoginHistoryByUserId(page, size, userId) {
    return await getHelper(`/users/${userId}/login-history?page=${page}&size=${size}`);
}
