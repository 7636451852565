import { toast, Bounce } from 'react-toastify';

const notifyOptions = {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'light',
    transition: Bounce
};

const notify = (type, message, options = {}) => {
    const sOptions = { ...notifyOptions, ...options };
    switch (type) {
        case 'info':
            return toast.info(message, sOptions);
        case 'success':
            return toast.success(message, sOptions);
        case 'error':
            return toast.error(message, sOptions);
        default:
            return toast(message, sOptions);
    }
};

export default notify;
