import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, Box, Select, MenuItem, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { TextField } from '@mui/material';

export default function AssignCurrentLeads(props) {
    const { open, rowData, onClose, fetchUpdatedData } = props;
    const [leadIds, setLeadIds] = useState([]); // Store lead IDs

    const [leadRequests, setLeadRequests] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState('');
    const [selectedRequesterId, setSelectedRequesterId] = useState('');
    const [selectedRequesterName, setSelectedRequesterName] = useState('');

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        let headers = {};

        if (accessToken) {
            headers = {
                Authorization: `Bearer ${accessToken}`
            };
        }

        fetch(`${process.env.REACT_APP_API_URL}/lead-requests`, {
            headers: headers // Include the Authorization header
        })
            .then((response) => response.json())
            .then((data) => {
                setLeadRequests(data?.list);
            })
            .catch((error) => {
                console.error('Error fetching requester options: ', error);
            });
    }, []);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // ... other state variables and useEffect for leadRequests

    useEffect(() => {
        const extractedIds = rowData?.map((item) => item.id) || [];
        setLeadIds(extractedIds);
    }, [rowData]);

    const handleAddClick = () => {
        // console.log(leadIds);
        // console.log(selectedOrder);
        // console.log(selectedRequesterId);

        const requestBody = JSON.stringify({
            lead_ids: leadIds,
            lead_request_id: selectedOrder
        });

        console.log('Request body : ', requestBody);
        console.log(leadRequests);

        fetch(`${process.env.REACT_APP_API_URL}/leads/assign/agent/${selectedRequesterId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            },
            body: requestBody
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                // Handle the successful response here
                console.log('PUT request successful:', data);
                console.log(requestBody, selectedRequesterId);
                fetchUpdatedData();
                onClose();
            })
            .catch((error) => {
                // Handle errors here
                console.error('Error making PUT request:', error);
            });
    };

    const handleOrderChange = (event) => {
        const selectedOrderId = event.target.value;
        const selectedRequest = leadRequests.find((order) => order.id === selectedOrderId);

        if (selectedRequest) {
            setSelectedOrder(selectedOrderId);
            setSelectedRequesterId(selectedRequest.requester.id);
            setSelectedRequesterName(selectedRequest.requester.name);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <form
                autoComplete="off"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '40px'
                }}
            >
                <h1>Assign</h1>

                {/* Select Requester */}
                <InputLabel id="requester-label">Select Order</InputLabel>
                <Select
                    labelId="requester-label"
                    id="requester"
                    variant="filled"
                    color="secondary"
                    value={selectedOrder}
                    onChange={handleOrderChange}
                    sx={{
                        mb: 2,
                        width: '360px',
                        border: '1px solid #ccc',
                        borderRadius: '8px'
                    }}
                    fullWidth
                >
                    {leadRequests.map((order) => (
                        <MenuItem key={order.id} value={order.id}>
                            {order.alias}
                        </MenuItem>
                    ))}
                </Select>

                <InputLabel id="agent-label">Select Agent</InputLabel>
                <TextField
                    labelId="agent-label"
                    id="agent"
                    variant="filled"
                    color="secondary"
                    value={selectedRequesterName}
                    sx={{
                        mb: 2,
                        width: '360px',
                        border: '1px solid #ccc',
                        borderRadius: '8px'
                    }}
                    fullWidth
                    disabled
                />

                <Box sx={{ marginTop: '20px' }}>
                    <Button
                        variant="contained"
                        type="button"
                        sx={{
                            height: 45,
                            width: 360,
                            color: 'white',
                            background: colors.primary[500],
                            fontSize: '15px',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                                background: '#5A4532'
                            }
                        }}
                        onClick={handleAddClick}
                    >
                        Assign
                    </Button>
                </Box>
            </form>
        </Dialog>
    );
}
