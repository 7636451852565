import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Appointment from './apointment';
import Button from '@mui/material/Button';

function TableListLeads(props) {
    const { rows, columns } = props;
    const [isAppointmentOpen, setIsAppointmentOpen] = useState(false);
    const [selectedRowData1, setSelectedRowData1] = useState(null);

    const [dialogOpenAppointment, setDialogOpenAppointment] = useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleAppintmentClick = (row) => {
        // Set the selectedRow state with the row data
        setSelectedRowData1(row);
        setIsAppointmentOpen(true);
        console.log('Appointment dialog should be open now', row);
    };

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            minWidth: column.minWidth,
                                            border: '1px solid rgba(0, 0, 0, 0.1)',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            sx={{
                                                '&:nth-of-type(even)': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                                }
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id === 'appointments' ? (
                                                            <Button
                                                                aria-label="edit"
                                                                type="submit"
                                                                sx={{
                                                                    color: 'white',
                                                                    background: '#a68e74',
                                                                    fontSize: '15px',
                                                                    width: '90px',
                                                                    transition:
                                                                        'background-color 0.3s',
                                                                    '&:hover': {
                                                                        background: '#5A4532'
                                                                    }
                                                                }}
                                                                size="small"
                                                                onClick={() =>
                                                                    handleAppintmentClick(row)
                                                                }
                                                            >
                                                                Set
                                                            </Button>
                                                        ) : (
                                                            value
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Appointment
                open={isAppointmentOpen}
                rowData={selectedRowData1}
                onClose={() => setIsAppointmentOpen(false)}
                // updateTableData={updateTableData} // Pass update function as prop
            />
        </>
    );
}
export default TableListLeads;
