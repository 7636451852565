import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';
import { useState } from 'react';
import { TextField, Box, Grid } from '@mui/material';
import './appointment.css'; // Import your CSS file for styling
import { useEffect } from 'react';

export default function Appointment(props) {
    const { open, rowData, onClose, updateTableData } = props;
    const [notes, setNotes] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [availableTimeSlots, setAvailableTimeSlots] = useState({});

    const accessToken = localStorage.getItem('access_token');

    const theme = useTheme();

    const colors = tokens(theme.palette.mode);

    const generateAvailableTimeSlots = (startDate) => {
        const availableTimeSlots = {};
        let currentDate = new Date(startDate);

        for (let i = 0; i < 30; i++) {
            const dayOfWeek = currentDate.getDay();
            const dateString = currentDate.toISOString().slice(0, 10);

            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                const availableSlots = [
                    { start: '09:00', end: '10:00' },
                    { start: '10:00', end: '11:00' },
                    { start: '11:00', end: '12:00' },
                    { start: '13:00', end: '14:00' },
                    { start: '14:00', end: '15:00' },
                ];
                availableTimeSlots[dateString] = availableSlots;
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return availableTimeSlots;
    };

    useEffect(() => {
        const startDate = new Date(); // Start from current date
        const slots = generateAvailableTimeSlots(startDate);
        setAvailableTimeSlots(slots);
    }, []); // Run only once on component mount

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        setSelectedDate(selectedDate);
        setSelectedTimeSlot('');
    };

    const handleTimeSlotChange = (event) => {
        const selectedTimeSlot = event.target.value;
        setSelectedTimeSlot(selectedTimeSlot);
    };

    const getTimeSlotsForDate = () => {
        if (selectedDate && availableTimeSlots[selectedDate]) {
            return availableTimeSlots[selectedDate].map((timeSlot, index) => (
                <option key={index} value={`${timeSlot.start}-${timeSlot.end}`}>
                    {`${timeSlot.start} - ${timeSlot.end}`}
                </option>
            ));
        }
        return null;
    };

    const handleCancel = () => {
        setSelectedDate('');
        setSelectedTimeSlot('');
        setNotes('');
        onClose(); // Call the onClose method to close the modal and reset values
    };

    const handleSetAppointment = async () => {
        if (selectedDate && selectedTimeSlot && notes && rowData?.id) {
            const [startTime, endTime] = selectedTimeSlot.split('-').map((time) => time.trim());

            const requestBody = {
                date: selectedDate,
                start_time: startTime,
                end_time: endTime,
                lead_id: rowData.id,
                notes: notes,
            };

            try {
                const response = await await fetch(`${process.env.REACT_APP_API_URL}/appointments`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (response.ok) {
                    const responseData = await response.json();
                    console.log('Appointment set successfully!');
                    console.log('Request Body:', requestBody);
                    console.log('Response:', responseData);
                    setSelectedDate('');
                    setSelectedTimeSlot('');
                    setNotes('');
                    onClose();
                } else {
                    console.error('Error setting appointment:', response.statusText);
                }
            } catch (error) {
                console.error('Error setting appointment:', error);
            }
        } else {
            console.error('Please fill in all necessary fields.');
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    width: '1000px',
                    height: '800px',
                },
            }}
        >
            <form
                autoComplete="off"
                style={{
                    display: 'flex',
                    flexDirection: 'column',

                    margin: '20px',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* First Row */}
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <h3>Name</h3>
                                <p>{rowData?.name}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <h3>Email Address</h3>
                                <p>{rowData?.email}</p>
                            </Grid>
                            <Grid item xs={4}>
                                <h3>Type</h3>
                                <p>{rowData?.type}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Zip Code</h3>
                        <p>{rowData?.zip_code}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <p>{rowData?.description}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="appointment-scheduler">
                            <h2>Schedule an Appointment</h2>
                            <div style={{ display: 'flex', direction: 'row' }}>
                                <div className="input-group">
                                    <label htmlFor="date">Select Date:</label>
                                    <input type="date" id="date" value={selectedDate} onChange={handleDateChange} />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="time">Available Slots:</label>
                                    <select id="time" value={selectedTimeSlot} onChange={handleTimeSlotChange} disabled={!selectedDate}>
                                        <option value="">Select a time</option>
                                        {getTimeSlotsForDate()}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {selectedDate && selectedTimeSlot && (
                            <p className="confirmation">
                                Appointment scheduled on {selectedDate} at {selectedTimeSlot}
                            </p>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Additional Notes"
                            onChange={(e) => setNotes(e.target.value)}
                            variant="filled"
                            color="secondary"
                            multiline
                            rows={6}
                            type="text"
                            sx={{
                                mb: 2,
                                width: '100%',
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                marginTop: '10px',
                            }}
                            value={notes}
                            InputProps={{ disableUnderline: true }}
                        />
                        <div style={{ flexGrow: 1 }}></div>

                        <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                type="button"
                                sx={{
                                    width: 220,
                                    height: 45,
                                    color: 'white',
                                    background: '#a68e74',
                                    fontSize: '15px',
                                    transition: 'background-color 0.3s',
                                    '&:hover': {
                                        background: '#5A4532',
                                    },
                                }}
                                onClick={handleSetAppointment}
                            >
                                Set
                            </Button>
                        </Box>
                        <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                type="button"
                                sx={{
                                    height: 45,
                                    width: 220,
                                    color: 'white',
                                    background: '#dd6266',
                                    fontSize: '15px',
                                    transition: 'background-color 0.3s',
                                    '&:hover': {
                                        background: '#8B0000',
                                    },
                                }}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {/* <div style={{ flexGrow: 1 }}></div> */}
            </form>
        </Dialog>
    );
}
