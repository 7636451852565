import { useAuthContext } from './context/AuthContext';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
    const { user } = useAuthContext();

    if (!user) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/auth/login" />;
    } else {
        <Navigate to="/" replace />;
    }

    // authorized so return child components
    return children;
}

export { PrivateRoute };
