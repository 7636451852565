import Sidebar from './Sidebar';
import Topbar from './Topbar';

const PageWrapper = ({ children }) => {
    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <div style={{ padding: '1.5rem' }}>{children}</div>
            </main>
        </>
    );
};

export default PageWrapper;
