import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { InfoOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// import AssignLeads from './assignLeads';

export default function TableCurrentOrders(props) {
    const { rows, columns } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dialogOpenEdit, setDialogOpenEdit] = useState(false);
    const [dialogOpenBlock, setDialogOpenBlock] = useState(false);
    const [dialogOpenAppointment, setDialogOpenAppointment] = useState(false);
    const [orderQuantities, setOrderQuantities] = useState(rows.map((row) => row.orderQuantity));

    const [isOrdersDialogOpen, setIsOrdersDialogOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const navigate = useNavigate();

    const handleOrdersClick = (row) => {
        // Set the selectedRow state with the row data
        setSelectedRowData(row);
        // setIsOrdersDialogOpen(true);
        navigate('/current-orders/order', { state: { rowData: row } });
        console.log('Orders dialog should be open now', row);
    };

    //.................................................//

    const { value, number } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };
    //..................................................//
    const theme = useTheme();

    const colors = tokens(theme.palette.mode);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        console.log('Row Data:', selectedRowData);
    }, [selectedRowData]);

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                        border: '1px solid rgba(0, 0, 0, 0.1)',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.code}
                                        sx={{
                                            '&:nth-of-type(even)': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                            }
                                        }}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.id === 'actions' ? (
                                                        <>
                                                            <div style={{ display: 'flex' }}>
                                                                <Tooltip title="Show" arrow>
                                                                    <IconButton
                                                                        aria-label="edit"
                                                                        sx={{
                                                                            marginRight: '3px'
                                                                        }}
                                                                        size="small"
                                                                        onClick={() =>
                                                                            handleOrdersClick(row)
                                                                        }
                                                                    >
                                                                        <InfoOutlined />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        </>
                                                    ) : column.format &&
                                                      typeof value === 'number' ? (
                                                        column.format(value)
                                                    ) : (
                                                        value
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
