import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, Box, Select, MenuItem, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { TextField } from '@mui/material';

export default function AssignLeads(props) {
    const { open, rowData, onClose } = props;
    const [assignLeads, setAssignLeads] = useState(0); // State for the number of leads to assign

    const [exceedsAvailable, setExceedsAvailable] = useState(false); // State to track if assigned leads exceed available leads

    const handleAssignLeads = () => {
        if (assignLeads > rowData.quantity) {
            setExceedsAvailable(true);
        } else {
            setExceedsAvailable(false);
            // Perform actions with the value in 'assignLeads'
            // Example: Make an API call, update state, etc.
            console.log('Assigned leads:', assignLeads);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <form
                autoComplete="off"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '40px',
                }}
            >
                <h1>Assign Leads</h1>

                <TextField label="Available Leads" variant="outlined" value={rowData?.quantity} disabled style={{ marginBottom: '20px', width: '300px' }} />

                <TextField
                    label="Assign Leads"
                    variant="outlined"
                    value={assignLeads}
                    onChange={(e) => {
                        const input = parseInt(e.target.value, 10);
                        if (!isNaN(input)) {
                            setAssignLeads(input);
                        } else {
                            setAssignLeads(0);
                        }
                    }}
                    style={{ marginBottom: '20px', width: '300px' }}
                />

                {exceedsAvailable && <p style={{ color: 'red', marginBottom: '10px' }}>Cannot assign more than available leads</p>}
                <Box sx={{ marginTop: '20px' }}>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={handleAssignLeads}
                        sx={{
                            height: 45,
                            width: 360,
                            color: 'white',
                            background: '#a68e74',
                            fontSize: '15px',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                                background: '#5A4532',
                            },
                        }}
                    >
                        Assign
                    </Button>
                </Box>
            </form>
        </Dialog>
    );
}
