import { useForm } from 'react-hook-form';
import AgentCommonForm from '../common/form';
import PageWrapper from '../../../components/global/PageWrapper';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import LogInHistoryTable from '../../../components/logInHistoryTable';
import { useQuery } from 'react-query';
import { getAgentById } from '../../../api/agents';
import { useEffect } from 'react';

const AgentDetailPage = () => {
    const { id } = useParams();
    const { isLoading, data } = useQuery(['AgentDetail'], () => getAgentById(id));

    const {
        control,
        formState: { errors },
        reset
    } = useForm();

    useEffect(() => {
        if (!isLoading && data?.data) {
            reset({
                ...data?.data
            });
        }
    }, [isLoading]);

    return (
        <PageWrapper>
            {!isLoading && (
                <AgentCommonForm
                    title={'Agent Detail'}
                    control={control}
                    errors={errors}
                    readOnly={true}
                    data={data?.data}
                />
            )}
            <Box sx={{ padding: '1rem' }} />
            <LogInHistoryTable userId={id} />
        </PageWrapper>
    );
};

export default AgentDetailPage;
