import React from 'react';
import TableCurrentOrders from '../../components/current-orders/tableCurrentOrders';
import { Box } from '@mui/material';
import Sidebar from '../../components/global/Sidebar';
import Topbar from '../../components/global/Topbar';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { CurrentOrdersGet } from '../../api/current-orders';
import CircularIndeterminate from '../../components/circularProgress';

const columns = [
    { id: 'alias', label: 'Order #', minWidth: 150 },
    { id: 'id', label: 'Order ID', minWidth: 150 },

    {
        id: 'status',
        label: 'Order Status',
        minWidth: 140,
        align: 'left',
        format: (value) => value.toLocaleString('en-US')
    },

    {
        id: 'actions',
        label: 'Order Details',
        minWidth: 100,
        align: 'left'
    }
];

function createData(
    alias,
    id,
    status,
    additional_notes,
    requesterName,
    lead_request_items,
    requester,
    provider
) {
    return {
        alias,
        id,
        status,
        additional_notes,
        requesterName,
        lead_request_items,
        requester,
        provider
    };
}

const CurrentOrders = () => {
    const {
        data: currentOrdersData,
        error,
        isLoading
    } = useQuery('CurrentOrdersGet', CurrentOrdersGet);

    console.log('from currrent orders: ', currentOrdersData?.data?.list);

    const rows = isLoading
        ? []
        : currentOrdersData?.data?.list?.map((item, index) =>
              createData(
                  item?.alias,
                  item?.id,
                  item?.status,
                  item?.additional_notes,
                  item?.requester?.name,
                  item?.lead_request_items,
                  item?.requester,
                  item?.provider
              )
          );
    console.log(rows);
    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <div>
                    <Box
                        sx={{
                            marginLeft: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginRight: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginbottom: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h1 style={{ textAlign: 'left', fontSize: '2.5rem' }}>
                                Current Orders
                            </h1>
                        </Box>
                        {isLoading ? (
                            <CircularIndeterminate />
                        ) : error ? (
                            <div>Error: {error.message}</div>
                        ) : (
                            <TableCurrentOrders rows={rows} columns={columns} />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '20px'
                            }}
                        ></Box>
                    </Box>
                </div>
            </main>
        </>
    );
};

export default CurrentOrders;
