import { Controller } from 'react-hook-form';
import { TextField, Box } from '@mui/material';

const CustomHookFormInput = (props) => {
    const { name, label, rules, type, readOnly, errorMessage, control } = props;
    return (
        <Box>
            <Controller
                name={name}
                rules={rules}
                control={control}
                render={({ field: { ref, ...field } }) => (
                    <TextField
                        {...field}
                        inputRef={ref}
                        label={label}
                        variant="filled"
                        color="secondary"
                        type={type}
                        fullWidth
                        InputProps={{ disableUnderline: true, readOnly }}
                    />
                )}
            />
            <div style={{ color: 'red' }}>{errorMessage}</div>
        </Box>
    );
};

export default CustomHookFormInput;
