import { useForm } from 'react-hook-form';
import CallingCenterVendorCommonForm from '../common/form';
import PageWrapper from '../../../components/global/PageWrapper';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import LogInHistoryTable from '../../../components/logInHistoryTable';
import { useQuery } from 'react-query';
import { getCallingCenterVendorById } from '../../../api/calling-center-vendors';
import { useEffect } from 'react';

const CallingCenterVendorDetailPage = () => {
    const { id } = useParams();
    const { isLoading, data } = useQuery(['CallingCenterVendorDetail'], () =>
        getCallingCenterVendorById(id)
    );

    const {
        control,
        formState: { errors },
        reset
    } = useForm();

    useEffect(() => {
        if (!isLoading && data?.data) {
            reset({
                ...data?.data
            });
        }
    }, [isLoading]);

    return (
        <PageWrapper>
            {!isLoading && (
                <CallingCenterVendorCommonForm
                    title={'Calling Center Vendor Detail'}
                    control={control}
                    errors={errors}
                    readOnly={true}
                    data={data?.data}
                />
            )}
            <Box sx={{ padding: '1rem' }} />
            <LogInHistoryTable userId={id} />
        </PageWrapper>
    );
};

export default CallingCenterVendorDetailPage;
