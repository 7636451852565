import React from 'react';
import { Box, IconButton, Typography, Button } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import Stack from '@mui/material/Stack';
import { useAuthContext } from '../../context/AuthContext';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
    const { user, logout } = useAuthContext();

    const email = user?.email?.match(/^[^0-9\W]+/)[0];
    const theme = useTheme();
    const navigate = useNavigate();

    const colors = tokens(theme.palette.mode);

    const handleLogout = () => {
        logout();
        navigate('/auth/login');
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            pt={1}
            pr={2}
            marginRight={2}
        >
            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                borderBottom={1}
                borderColor="grey.300"
            ></Stack>
            {/* Icons */}
            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                borderBottom={1}
                borderColor="grey.300"
                paddingBottom={1}
                marginRight={5}
                marginTop={1}
                width={300}
                sx={{ justifyContent: 'flex-end' }} // Align items to the right
            >
                {/* <IconButton>
                    <NotificationAddOutlinedIcon sx={{ width: 25, height: 25 }} />
                </IconButton> */}

                <Typography
                    sx={{
                        fontWeight: 'bold',
                        marginTop: { xs: '10px', sm: '0' },
                        marginLeft: { xs: '0', sm: '25px' }
                    }}
                >
                    {email}
                </Typography>

                <IconButton>
                    <PersonOutlineOutlinedIcon sx={{ width: 32, height: 32 }} />
                </IconButton>
                <Button
                    variant="contained"
                    type="submit"
                    sx={{
                        height: 40,
                        width: 90,
                        color: 'white',
                        background: colors.primary[500],
                        fontSize: '12px',
                        transition: 'background-color 0.3s',
                        borderRadius: '90px', // Add border radius here
                        '&:hover': {
                            background: '#5A4532' // Dark brown color on hover
                        }
                    }}
                    onClick={handleLogout}
                >
                    <Link
                        to="/"
                        style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                    >
                        Log out
                    </Link>
                </Button>
            </Stack>
        </Stack>
    );
};

export default Topbar;
