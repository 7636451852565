import React, { useState, useEffect } from 'react';
import TableListLeads from '../../components/list-leads/tableListLeads';
import { Box } from '@mui/material';
import Appointment from '../../components/list-leads/apointment';
import Sidebar from '../../components/global/Sidebar';
import Topbar from '../../components/global/Topbar';
import CircularIndeterminate from '../../components/circularProgress';
import { useQuery } from 'react-query';
import { ListLeadsGet } from '../../api/list-leads';

const columns = [
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'zip_code', label: 'Zip Code', minWidth: 150 },
    {
        id: 'email',
        label: 'Email Address',
        minWidth: 140,
        align: 'left',
        format: (value) => value.toLocaleString('en-US')
    },
    {
        id: 'type',
        label: 'Type',
        minWidth: 140,
        align: 'left',
        format: (value) => value.toLocaleString('en-US')
    },

    {
        id: 'status',
        label: 'Status',
        minWidth: 140,
        align: 'left',
        format: (value) => value.toLocaleString('en-US')
    },
    {
        id: 'appointments',
        label: 'Appointments',
        minWidth: 140,
        align: 'left',
        format: (value) => value.toFixed(2)
    }
];

function createData(name, zip_code, email, type, status, description, id) {
    return { name, zip_code, email, type, status, description, id };
}

const ListLeads = () => {
    const { data: LeadsData, error, isLoading } = useQuery('LeadsGet', ListLeadsGet);
    console.log(LeadsData);

    const rows = isLoading
        ? [] // Initialize it as an empty array when loading
        : LeadsData.data.list.map((item) =>
              createData(
                  item.name,
                  item.zip_code,
                  item.email,
                  item.type.name,
                  item.status,
                  item.description,
                  item.id
              )
          );

    console.log(rows);

    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <div>
                    <Box
                        sx={{
                            marginLeft: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginRight: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginbottom: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h1 style={{ textAlign: 'left', fontSize: '2.5rem' }}>
                                List of Leads by Order
                            </h1>
                        </Box>
                        {isLoading ? (
                            <CircularIndeterminate />
                        ) : error ? (
                            <div>Error: {error.message}</div>
                        ) : (
                            <TableListLeads rows={rows} columns={columns} />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '20px'
                            }}
                        ></Box>
                    </Box>
                </div>
            </main>
        </>
    );
};
export default ListLeads;
