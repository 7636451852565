import { Box, Grid, Typography } from '@mui/material';
import CustomHookFormInput from '../../../components/CustomHookFormInput';
import ResourceStatusDetail from '../../../components/ResourceStatusDetail';

const AgentCommonForm = ({ title, control, errors, readOnly, data }) => {
    const fields = [
        {
            name: 'name',
            label: 'Name',
            type: 'text',
            rules: { required: true }
        },
        {
            name: 'email',
            label: 'Email',
            type: 'text',
            rules: { required: true }
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'text',
            rules: { required: true }
        },
        {
            name: 'role',
            label: 'Role',
            type: 'text',
            rules: { required: true }
        }
    ];

    return (
        <>
            <Typography variant="h4" fontWeight={600}>
                {title}
            </Typography>
            <Box sx={{ padding: '.6rem' }} />
            <Grid container spacing={2}>
                {fields.map((field) => (
                    <Grid item xs={12} md={6}>
                        <CustomHookFormInput
                            key={field.name}
                            name={field.name}
                            label={field.label}
                            type={field.type}
                            rules={field.rules}
                            control={control}
                            readOnly={readOnly}
                            errorMessage={errors?.[field.name] && 'This field is required'}
                        />
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ padding: '.6rem' }} />
            <ResourceStatusDetail
                created_at={data.created_at}
                updated_at={data.updated_at}
                deleted_at={data.deleted_at}
            />
        </>
    );
};

export default AgentCommonForm;
