import { ColorModeContext, useMode } from './theme';
import { CssBaseline } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import CallingCenterVendors from './scenes/calling-center-vendors';
import CurrentLeads from './scenes/current-leads';
import CurrentOrders from './scenes/current-orders';
import ApprovalDisapproval from './scenes/approval-disapproval';
import ListLeads from './scenes/list-leads';
import { PrivateRoute } from './privateRoutes';
import Training from './scenes/training';
import TableOrders from './components/current-orders/tableorders';
import EditManageAgentPage from './components/manage-agents/editManageAgentPage';
import EditCallingVendorsPage from './components/calling-center-vendors/editCallingVendorsPage';
import AddNewLeadVendorsPage from './components/leads-vendors/addNewLeadVendorsPage';
import AddNewCallingVendorPage from './components/calling-center-vendors/addNewCallingVendorPage';
import PublicRoutes from './publicRoutes';
import PreviousOrders from './scenes/previous-orders';
import PaymentHistory from './scenes/payment-history';
import TableOrdersP from './components/previous-orders/tableOrdersP';
import { ToastContainer } from 'react-toastify';
import React, { Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import CircularIndeterminate from './components/circularProgress';
import AgentDetailPage from './scenes/manage-agents/detail';
import LeadVendorDetailPage from './scenes/manage-leads-vendor/detail';
import CallingCenterVendorDetailPage from './scenes/calling-center-vendors/detail';
import EditVendorsPage from './scenes/manage-leads-vendor/edit';

const HomePage = React.lazy(() => import('./scenes/home'));
const SignInPage = React.lazy(() => import('./scenes/auth/login'));
const ForgotPasswordPage = React.lazy(() => import('./scenes/auth/forgot-password'));
const ResetPasswordPage = React.lazy(() => import('./scenes/auth/reset-password'));
const AgentsListPage = React.lazy(() => import('./scenes/manage-agents/list'));
const LeadVendorListPage = React.lazy(() => import('./scenes/manage-leads-vendor/list'));
const CallingCenterVendorsListPage = React.lazy(() =>
    import('./scenes/calling-center-vendors/list')
);
const AddLeadVendors = React.lazy(() => import('./scenes/manage-leads-vendor/add'));
const AddCallingCenterVendors = React.lazy(() => import('./scenes/calling-center-vendors/add'));

const RenderPublicRoutes = () => {
    const routes = [
        {
            path: '/auth/login',
            component: SignInPage
        },
        {
            path: '/auth/forgot-password',
            component: ForgotPasswordPage
        },
        {
            path: '/auth/reset-password/:token',
            component: ResetPasswordPage
        }
    ];

    return routes.map((route) => (
        <Route
            key={route.path}
            path={route.path}
            element={
                <PublicRoutes>
                    <route.component />
                </PublicRoutes>
            }
        />
    ));
};

const RenderPrivateRoutes = () => {
    const routes = [
        {
            path: '/',
            component: HomePage
        },
        {
            path: '/manage-agents/:id',
            component: AgentDetailPage
        },
        {
            path: '/manage-agents',
            component: AgentsListPage
        },
        {
            path: '/manage-lead-vendors/:id',
            component: LeadVendorDetailPage
        },
        {
            path: '/manage-lead-vendors',
            component: LeadVendorListPage
        },
        {
            path: '/add-lead-vendors',
            component: AddLeadVendors
        },
        {
            path: '/edit-lead-vendors/:idd',
            component: EditVendorsPage
        },
        {
            path: '/manage-calling-center-vendors/:id',
            component: CallingCenterVendorDetailPage
        },
        {
            path: '/manage-calling-center-vendors',
            component: CallingCenterVendorsListPage
        },
        {
            path: '/add-calling-center-vendors',
            component: AddCallingCenterVendors
        },
        {
            path: '/current-leads',
            component: CurrentLeads
        },
        {
            path: '/current-orders',
            component: CurrentOrders
        },
        {
            path: '/current-orders/order',
            component: TableOrders
        },
        {
            path: '/previous-orders',
            component: PreviousOrders
        },
        {
            path: '/list-leads',
            component: ListLeads
        }
    ];

    return routes.map((route) => (
        <Route
            key={route.path}
            path={route.path}
            element={
                <PrivateRoute>
                    <route.component />
                </PrivateRoute>
            }
        />
    ));
};

function App() {
    const [colorMode] = useMode();

    return (
        <Suspense fallback={<CircularIndeterminate />}>
            <ColorModeContext.Provider value={colorMode}>
                <CssBaseline />
                <div className="app">
                    <ToastContainer />
                    <Routes>
                        {RenderPublicRoutes()}
                        {RenderPrivateRoutes()}
                    </Routes>
                    {/* <Routes>
                   
                    <Route
                        path="manageAgent"
                        element={
                            <PrivateRoute>
                                <ManageAgent />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="editManageAgent"
                        element={
                            <PrivateRoute>
                                <EditManageAgentPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="manageLeadsVendor"
                        element={
                            <PrivateRoute>
                                <ManageLeadsVendor />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="editVendor"
                        element={
                            <PrivateRoute>
                                <EditVendorsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="addVendor"
                        element={
                            <PrivateRoute>
                                <AddNewLeadVendorsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="callingCenterVendors"
                        element={
                            <PrivateRoute>
                                <CallingCenterVendors />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="editCallingVendor"
                        element={
                            <PrivateRoute>
                                <EditCallingVendorsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="addCallingVendor"
                        element={
                            <PrivateRoute>
                                <AddNewCallingVendorPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="currentOrders"
                        element={
                            <PrivateRoute>
                                <CurrentOrders />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="currentOrders/orders"
                        element={
                            <PrivateRoute>
                                <TableOrders />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="currentLeads"
                        element={
                            <PrivateRoute>
                                <CurrentLeads />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="previousOrders"
                        element={
                            <PrivateRoute>
                                <PreviousOrders />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="previousOrders/ordersP"
                        element={
                            <PrivateRoute>
                                <TableOrdersP />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="paymentHistory"
                        element={
                            <PrivateRoute>
                                <PaymentHistory />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="listAgents"
                        element={
                            <PrivateRoute>
                                <ListAgents />
                            </PrivateRoute>
                        }
                    />
                   c
                    <Route
                        path="training"
                        element={
                            <PrivateRoute>
                                <Training />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="approvalDisapproval"
                        element={
                            <PrivateRoute>
                                <ApprovalDisapproval />
                            </PrivateRoute>
                        }
                    />
                </Routes> */}
                </div>
            </ColorModeContext.Provider>
        </Suspense>
    );
}

export default App;
