import { getHelper, putHelper, deleteHelper, postHelper } from '../helpers/api';

export async function CallingCenterVendorsGet() {
    return await getHelper('/users/calling-agents');
}

export async function getPaginatedCallingCenterVendor(page, size) {
    return await getHelper(`/users/calling-agents?page=${page}&size=${size}`);
}

export async function getCallingCenterVendorById(id) {
    return await getHelper(`/users/${id}`);
}

// not being used currently
export async function CallingCenterVendorsAdd(body) {
    return await postHelper('/users', body);
}

// not being used currently
export async function CallingCenterVendorsUpdate(id) {
    return await putHelper('/users/calling-agents', id);
}

// not being used currently
export async function CallingCenterVendorsDelete(id) {
    return await deleteHelper('/users/calling-agents', id);
}
