import * as React from 'react';
import { getLoginHistory, getLoginHistoryByUserId } from '../api/users';
import { TableCell, TableRow } from '@mui/material';
import CustomTable from './CustomTable';
import usePaginationFetch from '../hooks/usePaginationFetch';

const columns = ['Name', 'Email', 'Role', 'Date / Time'];

const formattedRows = (rows) => {
    return (
        rows?.map((row) => (
            <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.role}</TableCell>
                <TableCell>{row.created_at}</TableCell>
            </TableRow>
        )) ?? <></>
    );
};

export default function LogInHistoryTable({ userId }) {
    const { page, size, isLoading, data, handlePageChange, handleSizePerPageChange } =
        usePaginationFetch(
            'LoginHistoryList',
            userId ? getLoginHistoryByUserId : getLoginHistory,
            userId ? [userId] : []
        );

    return (
        <CustomTable
            loading={isLoading}
            title={'Login History'}
            columns={columns}
            rows={formattedRows(data?.data?.list)}
            totalRecords={data?.data?.total ?? 0}
            currentPage={page}
            rowsPerPage={size}
            handlePageChange={handlePageChange}
            handleSizePerPageChange={handleSizePerPageChange}
        />
    );
}
