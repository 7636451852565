import { getHelper, putHelper, deleteHelper, postHelper } from '../helpers/api';

export async function ManageLeadsVendorGet() {
    return await getHelper('/users/vendors');
}

export async function getPaginatedLeadVendors(page, size) {
    return await getHelper(`/users/vendors?page=${page}&size=${size}`);
}

export async function getLeadVendorById(id) {
    return await getHelper(`/users/${id}`);
}

// not being used currently
export async function ManageLeadsVendorAdd(body) {
    return await postHelper('/users', body);
}

// not being used currently
export async function ManageLeadsVendorUpdate(id) {
    return await putHelper('/users/vendors', id);
}

// not being used currently
export async function ManageLeadsVendorDelete(id) {
    return await deleteHelper('/users/vendors', id);
}
