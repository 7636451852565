import { createContext, useContext, useState } from 'react';
import { getHelper, postHelper } from '../helpers/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const localStorageUserStr = localStorage.getItem('user');
        if (!localStorageUserStr) return null;
        const localStorageUser = JSON.parse(localStorageUserStr);
        return localStorageUser;
    });

    const makeLoginRequest = async (email, password) => {
        const { status, message, data } = await postHelper('/auth/login', {
            email,
            password
        });

        if (!status) {
            throw new Error(message);
        }

        const { access_token, refresh_token } = data;
        const {
            status: userDataStatus,
            data: userData,
            message: userDataMessage
        } = await getHelper('/users/me', {
            Authorization: `Bearer ${access_token}`
        });

        if (!userDataStatus) {
            throw new Error(userDataMessage);
        }

        return { user: userData, access_token, refresh_token };
    };

    const login = async (email, password) => {
        try {
            const data = await makeLoginRequest(email, password);

            const { user, access_token, refresh_token } = data;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            setUser(user);

            return { success: true, message: 'Successful.' };
        } catch (error) {
            return { success: false, message: error.message };
        }
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setUser(null);
    };

    const contextValue = {
        user,
        login,
        logout
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
