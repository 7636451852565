import { useAuthContext } from './context/AuthContext';
import { Navigate } from 'react-router-dom';

function PublicRoutes({ children }) {
    const { user } = useAuthContext();

    if (user) {
        // If the user is already logged in, redirect to the home page
        return <Navigate to="/" />;
    }

    // If the user is not logged in, allow access to the login page
    return children;
}

export default PublicRoutes;
