import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material';
import Sidebar from '../global/Sidebar';
import { useLocation } from 'react-router-dom';
import Topbar from '../global/Topbar';
import { Box } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import AssignLeads from './assignLeads';
import Button from '@mui/material/Button';

const columns = [
    { id: 'orderAlias', label: 'Order ID', minWidth: 150 },
    { id: 'leadType', label: 'Lead Type', minWidth: 150 },
    { id: 'zipCode', label: 'Zip Code', minWidth: 150 },
    { id: 'city', label: 'City', minWidth: 150 },
    { id: 'quantity', label: 'Quantity', minWidth: 150 }
    // { id: 'assign', label: 'Assign', minWidth: 150 }
];

function createData(orderAlias, leadType, zipCode, city, quantity, state, country) {
    return { orderAlias, leadType, zipCode, city, quantity, state, country };
}

function TableOrders() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedCityFilter, setSelectedCityFilter] = useState('all'); // Initialize with 'all' or any default value

    const location = useLocation();
    const [rowData, setRowData] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]); // State to store selected rows
    const [uniqueCities, setUniqueCities] = useState([]); // State to store unique cities

    useEffect(() => {
        const dataFromLocation = location.state && location.state.rowData;
        if (dataFromLocation) {
            setRowData(dataFromLocation);
        }
    }, [location.state]);

    useEffect(() => {
        console.log('data from orders page:', rowData);
    }, [rowData]);

    const handleCityFilterChange = (event) => {
        setSelectedCityFilter(event.target.value);
    };

    const filteredRows = rowData?.lead_request_items?.filter((item) => {
        if (selectedCityFilter === 'all') {
            return true;
        } else {
            return item.city === selectedCityFilter;
        }
    });
    console.log('Filtered rows: ', filteredRows);
    const rows = filteredRows?.map((item, index) => {
        const alias = rowData?.alias;
        return createData(alias, item?.type?.name, item?.zip_code, item?.city, item?.quantity);
    }); //.................................................//

    useEffect(() => {
        if (rowData && rowData.lead_request_items) {
            const cities = new Set();
            rowData.lead_request_items.forEach((item) => {
                cities.add(item.city);
            });
            setUniqueCities(Array.from(cities));
        }
    }, [rowData]);

    const handleOrderAssign = (row) => {
        setSelectedRowData(row);
        console.log('Order data :', row);
    };
    //..................................................//
    const theme = useTheme();

    const colors = tokens(theme.palette.mode);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [isAssignOpen, setIsAssignOpen] = useState(false);
    const [selectedRowData1, setSelectedRowData1] = useState(null);

    const logSelectedData = (row) => {
        setIsAssignOpen(true);
        setSelectedRowData1(row);
        console.log('Selected rows  : ', selectedRowData1);
    };

    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <div>
                    <Box
                        sx={{
                            marginLeft: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginRight: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginbottom: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h1 style={{ textAlign: 'left', fontSize: '2.5rem' }}>Orders </h1>
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '10px',
                                marginTop: '25px'
                            }}
                        >
                            <div></div>
                            <Select
                                sx={{ width: '150px' }}
                                value={selectedCityFilter}
                                onChange={handleCityFilterChange}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {uniqueCities.map((city) => (
                                    <MenuItem key={city} value={city}>
                                        {city}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 600 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        minWidth: column.minWidth,
                                                        border: '1px solid rgba(0, 0, 0, 0.1)',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows
                                            ?.slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.code}
                                                        sx={{
                                                            '&:nth-of-type(even)': {
                                                                backgroundColor:
                                                                    'rgba(0, 0, 0, 0.03)'
                                                            }
                                                        }}
                                                    >
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                >
                                                                    {column.id === 'assign' ? (
                                                                        <>
                                                                            {' '}
                                                                            {/* <Button
                                                                                variant="contained"
                                                                                type="submit"
                                                                                sx={{
                                                                                    width: 100,
                                                                                    color: 'white',
                                                                                    background:
                                                                                        colors
                                                                                            .primary[500],
                                                                                    fontSize:
                                                                                        '15px',
                                                                                    transition:
                                                                                        'background-color 0.3s',
                                                                                    '&:hover': {
                                                                                        background:
                                                                                            '#5A4532' // Dark brown color on hover
                                                                                    },
                                                                                    marginLeft:
                                                                                        'auto' // Align the button to the right
                                                                                }}
                                                                                onClick={() =>
                                                                                    logSelectedData(
                                                                                        row
                                                                                    )
                                                                                }
                                                                            >
                                                                                Assign
                                                                            </Button> */}
                                                                        </>
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                    <AssignLeads
                        open={isAssignOpen}
                        rowData={selectedRowData1}
                        onClose={() => setIsAssignOpen(false)}
                    />
                </div>
            </main>
        </>
    );
}

export default TableOrders;
