import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import Sidebar from '../../../components/global/Sidebar';
import Topbar from '../../../components/global/Topbar';
import { useLocation } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { useTheme } from '@mui/material';
import { putHelper } from '../../../helpers/api';
import { tokens } from '../../../theme';
import { getLeadVendorById } from '../../../api/manage-leads-vendor';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

const EditVendorsPage = () => {
    const { idd } = useParams();
    const { isLoading, data } = useQuery(['LeadVendorDetail'], () => getLeadVendorById(idd));

    console.log('from edit', data?.data.id);

    const navigate = useNavigate();

    const address = data?.data?.profile?.address;

    let cityy = '',
        statee = '',
        zip_codee = '',
        countryy = '';

    if (address && typeof address === 'object') {
        // Destructure address properties if address is an object
        cityy = address?.city || '';
        statee = address?.state || '';
        countryy = address?.country || '';
        zip_codee = address?.zip_code || '';
    }

    const [name, setName] = useState(`${data?.data?.name || ''}`);
    const [zip_code, setZip_code] = useState(zip_codee || '');
    const [email, setEmail] = useState(`${data?.data?.email || ''}`);
    const [city, setCity] = useState(cityy || '');
    const [state, setState] = useState(statee || '');
    const [phone, setPhone] = useState(`${data?.data?.phone || ''}`);
    const [rate_per_lead, setRate_per_lead] = useState(`${data?.data?.profile.rate || ''}`);
    const [country, setCountry] = useState(countryy || '');
    const [type, setType] = useState(`${data?.data?.type || 'All'}`);
    const [apiKey, setApiKey] = useState(`${data?.data.api_key?.key || ''}`);

    const [id, setId] = useState(`${data?.data?.id || ''}`);

    const theme = useTheme();

    const colors = tokens(theme.palette.mode);

    const handleSaveAndClose = async () => {
        // Prepare the data to send to the API in the specified format
        const updatedData = {
            name,
            email,
            phone,
            is_active: true,
            profile: {
                rate: rate_per_lead
            },
            address: {
                city,
                state,
                country,
                zip_code
            }
        };
        console.log('updatedData:', updatedData);

        // try {
        //     console.log('inside api', `/users/${data.data.id}`);
        //     const { status, message } = await putHelper(updatedData, `/users/${data?.data.id}`);
        //     if (message === 'success') {
        //         navigate('/manage-leads-vendor');
        //     } else {
        //         console.error('Put request failed:', status.statusText);
        //     }
        // } catch (error) {
        //     console.error('An error occurred:', error);
        // }
    };

    useEffect(() => {
        // Update the state variables when rowData changes
        setName(`${data?.data?.name || ''}`);
        setEmail(`${data?.data?.email || ''}`);
        setPhone(`${data?.data?.phone || ''}`);
        setRate_per_lead(`${data?.data?.profile.rate || ''}`);
        setType(`${data?.data?.type || ''}`);
        setId(`${data?.data?.id || ''}`);

        // Update address fields if address exists in rowData
        if (address && typeof address === 'object') {
            setCity(address.city || '');
            setState(address.state || '');
            setCountry(address.country || '');
            setZip_code(address.zip_code || '');
        }
    }, [data?.data, address]);

    return (
        <>
            <Sidebar />
            <main className="content">
                <Topbar />
                <div>
                    <Box
                        sx={{
                            marginLeft: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginRight: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            },
                            marginbottom: {
                                xs: '20px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '10px'
                            }}
                        >
                            <h1 style={{ textAlign: 'left', fontSize: '2.5rem' }}>Edit Vendor</h1>
                        </Box>
                        <form
                            autoComplete="off"
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <TextField
                                        label="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        variant="filled"
                                        color="secondary"
                                        type="text"
                                        sx={{
                                            mb: 2,
                                            width: '560px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px'
                                        }}
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        variant="filled"
                                        color="secondary"
                                        type="text"
                                        sx={{
                                            mb: 2,
                                            width: '560px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px'
                                        }}
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label="Phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        variant="filled"
                                        color="secondary"
                                        type="text"
                                        sx={{
                                            mb: 2,
                                            width: '560px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px'
                                        }}
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label="Rate"
                                        value={rate_per_lead}
                                        onChange={(e) => setRate_per_lead(e.target.value)}
                                        variant="filled"
                                        color="secondary"
                                        type="number"
                                        sx={{
                                            mb: 2,
                                            width: '560px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px'
                                        }}
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Grid>

                                <Grid item md={6}>
                                    <TextField
                                        label="City"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        variant="filled"
                                        color="secondary"
                                        type="text"
                                        sx={{
                                            mb: 2,
                                            width: '560px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px'
                                        }}
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label="State"
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                        variant="filled"
                                        color="secondary"
                                        type="text"
                                        sx={{
                                            mb: 2,
                                            width: '560px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px'
                                        }}
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label="Country"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        variant="filled"
                                        color="secondary"
                                        type="text"
                                        sx={{
                                            mb: 2,
                                            width: '560px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px'
                                        }}
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label="Zip Code"
                                        value={zip_code}
                                        onChange={(e) => setZip_code(e.target.value)}
                                        variant="filled"
                                        color="secondary"
                                        type="text"
                                        sx={{
                                            mb: 2,
                                            width: '560px',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px'
                                        }}
                                        fullWidth
                                        InputProps={{ disableUnderline: true }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item md={6}>
                                    <Box sx={{ marginTop: '20px' }}>
                                        <Button
                                            variant="contained"
                                            type="button"
                                            sx={{
                                                height: 45,
                                                width: 360,
                                                color: 'white',
                                                background: colors.primary[500],
                                                fontSize: '15px',
                                                transition: 'background-color 0.3s',
                                                '&:hover': {
                                                    background: '#5A4532'
                                                }
                                            }}
                                            onClick={handleSaveAndClose}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        <div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    margin: '10px'
                                }}
                            >
                                <h1 style={{ textAlign: 'left', fontSize: '2.5rem' }}>API Key</h1>
                            </Box>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ fontSize: '20px' }}>
                                    The API Key for this vendor is :{' '}
                                </p>
                                <h2 style={{ display: 'inline', marginLeft: '10px' }}>{apiKey}</h2>
                            </div>{' '}
                            <Box sx={{ marginTop: '20px' }}>
                                <Button
                                    variant="contained"
                                    type="button"
                                    sx={{
                                        height: 45,
                                        width: 360,
                                        color: 'white',
                                        background: colors.primary[500],
                                        fontSize: '15px',
                                        transition: 'background-color 0.3s',
                                        '&:hover': {
                                            background: '#5A4532'
                                        }
                                    }}
                                >
                                    Send API Connection Procedure
                                </Button>
                            </Box>
                        </div>
                    </Box>
                </div>
            </main>
        </>
    );
};

export default EditVendorsPage;
