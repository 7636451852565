import { useState } from 'react';
import { useQuery } from 'react-query';

function usePaginationFetch(
    label,
    urlFn,
    urlFnParams = [],
    queryFilter = '',
    currentPage = 1,
    sizePerPage = 10
) {
    const [page, setPage] = useState(currentPage);
    const [size, setSize] = useState(sizePerPage);

    const { isLoading, error, data } = useQuery([label, page, size, queryFilter, urlFnParams], () =>
        urlFn.apply(this, [page, size, ...urlFnParams])
    );

    const handlePageChange = (page) => {
        setPage(page + 1);
    };

    const handleSizePerPageChange = (size) => {
        setSize(size);
    };

    return { page, size, isLoading, error, data, handlePageChange, handleSizePerPageChange };
}

export default usePaginationFetch;
